import React from 'react'
import { Container } from 'components/library'
import { ChevronRight } from 'react-feather'
import { graphql } from 'gatsby'
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { CtaMailUpdates } from "components/common/cta-mail-updates";
import ConnectWebinar from "images/webinar-connect.jpg";
import { EventArticleBlock } from 'components/pages/events/event-article-block';
import { RegisterWebinarForm } from 'components/pages/webinar/register-webinar-form';
import { WebinarFeaturingSection } from 'components/pages/webinar/webinar-featuring-section';

const WEBINAR_LINK =
  "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MDBkNGVhODYtYjBkMy00Y2ZjLWJmMDItYmUwMmFmNzVhMjJm%40thread.v2/0?context=%7b%22Tid%22%3a%226b59d1a6-a603-49cd-b8b5-2a1411016e8b%22%2c%22Oid%22%3a%22c935ba3a-d589-4af8-8fa6-304ec55f405a%22%7d";

const Webinar = () => {
  const { t } = useTranslation();
  const webinar = {
    connect: {
      smalltitle: t("connect-webinar.smalltitle"),
      title: (
        <Trans
          i18nKey={"connect-webinar.title"}
          components={{
            br: <br />,
            heading: (
              <div className="text-2xl sm:text-[2.5rem] sm:leading-none" />
            ),
          }}
        />
      ),
      titleAlt: t("connect-webinar.title-alt"),
      date: t("connect-webinar.date"),
      isEnded: true,
      emdedLink: "https://www.youtube.com/embed/newNCml5DN0",
      duration: t("connect-webinar.duration"),
      time: t("connect-webinar.time"),
      image: ConnectWebinar,
      aboutThisEvent: [
        t("connect-webinar.aboutThisEvent.1"),
        t("connect-webinar.aboutThisEvent.2"),
      ],
      agenda: [
        t("connect-webinar.agenda.1"),
        t("connect-webinar.agenda.2"),
        t("connect-webinar.agenda.3"),
        t("connect-webinar.agenda.4"),
        t("connect-webinar.agenda.5"),
      ],
      bestFor: t("connect-webinar.bestFor"),
      whatYouWillLearn: [
        t("connect-webinar.whatYouWillLearn.1"),
        t("connect-webinar.whatYouWillLearn.2"),
        t("connect-webinar.whatYouWillLearn.3"),
      ],
      lectureTime: t("connect-webinar.lectureTime"),
      qnaTime: t("connect-webinar.qnaTime"),
      webinarLink: WEBINAR_LINK,
      featuredPersons: [],
    },
    labs: {},
    materialInformatics: {
      smalltitle: "",
      title:
        "Introduction to Material Informatics Platform and its Application",
      date: "28 April 2023",
      isEnded: false,
      emdedLink: "https://www.youtube.com/embed/newNCml5DN0",
      duration: "1 Hour",
      time: "11.00 a.m. - 12.00 p.m. IST",
      image: "/images/webinar-1.png",
      aboutThisEvent:
        "This webinar will provide an introduction to the material informatics field for students and professionals. Participants would learn the ways to utilize such platforms in their day-to-day product development processes that can accelerate material innovation. A product demo would be conducted for deeper understanding of the platform.",
      agenda: [
        "A technical introduction to the field and material informatics platform.",
        "Why material informatics is the need of the hour?",
        "How Material informatics will shape the future of the Materials industry?",
        "An overview of recent developments in the field.",
        "A Product demo.",
        "Application case studies and success stories.",
      ],
      bestFor:
        "Students, faculties, and industry professionals from polymer engineering, chemical engineering, and computer science background.",
      whatYouWillLearn: [
        "Technical introduction of Material informatics platform.",
        "Application of Material informatics platform.",
        "Future opportunities in Material informatics.",
      ],
      lectureTime: "45 mins",
      qnaTime: "15 mins",
      webinarLink: WEBINAR_LINK,
      featuredPersons: [
        {
          name: "Dr Abhijit Salvekar",
          image: "/images/founders/abhijit.png",
          role: "Co-Founder & CTO",
          company: "Polymerize",
        },
        {
          name: "Kunal Sandeep",
          image: "/images/founders/kunal.png",
          role: "Co-Founder & CEO",
          company: "Polymerize",
        },
      ],
    },
  };
  const selector = "connect";

  // HIDING WEBINAR FOR SOME TIME
  // return (
  // 	<section className="bg-404-mobile bg-cover lg:bg-404-desktop w-full">
  // 		<Container className="flex flex-col text-center justify-center items-center py-20 lg:py-40 gap-8">
  // 			<span className="text-5xl">{t("common.words.comming-soon")}</span>
  // 			<PrimaryBtn>{t("common.cta.go-home")}</PrimaryBtn>
  // 		</Container>
  // 	</section>
  // )

  return (
    <main className="bg-hero-bg-mobile lg:bg-hero-bg bg-no-repeat bg-contain -mt-28 sm:-mt-36 pt-28 sm:pt-36 min-h-[600px] w-full">
      <Container className="flex flex-col gap-20 pt-8 pb-20 text-white">
        {/* BREADCRUMBS */}
        <div className="flex items-center gap-2">
          <Link to="/">{t("common.words.home")}</Link>
          <ChevronRight className="shrink-0" size={16} />
          <Link to="/webinar" className="font-semibold">
            {t("common.words.webinar")}
          </Link>
        </div>

        {/* HERO */}
        <div className="flex flex-col gap-8">
          {webinar[selector].smalltitle ? (
            <span className="uppercase text-sm sm:text-lg lg:text-xl text-white max-w-max">
              {webinar[selector].smalltitle}
            </span>
          ) : null}

          <span className="text-5xl sm:text-7xl">
            {webinar[selector].title}
          </span>

          <div className="flex items-center gap-5">
            <span className="shrink-0">
              {webinar[selector].date} {" | "}{" "}
              {!webinar[selector].isEnded
                ? `Time : ${webinar[selector].duration}`
                : webinar[selector].time}
            </span>
            <span className="hidden sm:inline-flex w-full h-[1px] bg-white"></span>
          </div>

          <div className="w-full aspect-video bg-transparent">
            {!webinar[selector].isEnded && (
              <img
                src={webinar[selector].image}
                className="w-full h-full bg-transparent"
                alt={webinar[selector].titleAlt}
                loading="eager"
              />
            )}
            {webinar[selector].isEnded && (
              <iframe
                className="w-full h-full"
                src={webinar[selector].emdedLink}
                title={webinar[selector].titleAlt}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>

        {/* CONTENT */}
        <article
          className={
            "flex gap-10 flex-col items-center md:flex-row items-start text-neutral-900"
          }
        >
          <div className={"flex flex-col gap-5 md:w-[60%]"}>
            <EventArticleBlock
              contentType="paragraph"
              title="About this Event"
              content={webinar[selector].aboutThisEvent}
            />
            <EventArticleBlock
              contentType="list"
              title="Agenda"
              content={webinar[selector].agenda}
            />
            <EventArticleBlock
              contentType="paragraph"
              title="The Webinar is Best for"
              content={webinar[selector].bestFor}
            />
            <EventArticleBlock
              contentType="list"
              title="What will You Learn from this Webinar"
              content={webinar[selector].whatYouWillLearn}
            />
          </div>
          <div className="md:w-[40%]">
            <RegisterWebinarForm
              webinarLink={webinar[selector].webinarLink}
              title={webinar[selector].titleAlt}
              isEnded={webinar[selector].isEnded}
            />
          </div>
        </article>

        {/* WEBINAR CORE DETAILS */}
        {!webinar[selector].isEnded && (
          <div className="flex flex-row gap-10 text-1xl sm:text-2xl text-neutral-900 self-center">
            <p>
              {t("connect-webinar.registration")}{" "}
              {t("connect-webinar.free")}
            </p>
            <p>
              {t("connect-webinar.lecture")}{" "}
              {webinar[selector].lectureTime}
            </p>
            <p>
              {t("connect-webinar.qna")} {webinar[selector].qnaTime}
            </p>
          </div>
        )}
      </Container>

      {webinar[selector].featuredPersons.length > 0 ? (
        <WebinarFeaturingSection
          featured={webinar[selector].featuredPersons}
        />
      ) : null}

      <CtaMailUpdates />
    </main>
  );
};

export default Webinar

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
